import EOTState from './state';
import Vue from 'vue';
import Bugsnag from '@bugsnag/js';

export function initContract(contractContainer) {
    const containerData = contractContainer.dataset,
        state = JSON.parse(containerData.state),
        assignee = JSON.parse(containerData.assignee || ''),
        sellers = JSON.parse(containerData.sellers),
        userId = containerData.userId,
        userRole = containerData.userRole || 'sales_person',
        dashboardURL = window.atob(containerData.dashboard),
        endpoint = window.atob(containerData.updateEndpoint),
        commentEndpoint = window.atob(containerData.updateCommentEndpoint),
        logs = JSON.parse(document.getElementById('eot-contracts-logs').textContent);

    new Vue({
        el: contractContainer,
        name: 'EOTContract',
        components: { 'eot-state': EOTState },
        data() {
            return {
                userId,
                userRole,
                logs,
                state,
                assignee,
                sellers,
                form: {
                    state: state ? state.value : '',
                    comment: '',
                    assignee: assignee ? assignee.value : '',
                },
                editingComment: null,
                origComment: '',
                loading: false,
                hasError: false,
                dashboardURL,
                endpoint,
                commentEndpoint,
                commentUpdateLoading: false,
                hasCommentUpdateError: false,
            };
        },
        computed: {},
        watch: {
            'form.assignee'(value, oldValue) {
                if (value && oldValue) {
                    this.form.comment =
                        'Zugewiesen zu ' +
                        this.sellers.find((s) => s.value === value).label +
                        '.\n' +
                        this.form.comment;
                }
            },
        },
        methods: {
            async submitUpdate() {
                this.hasError = false;
                this.loading = 'update';

                const response = await fetch(this.endpoint, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        state: this.form.state,
                        comment: this.form.comment,
                        assignee: this.form.assignee,
                    }),
                });

                this.loading = false;

                if (response.ok) {
                    const data = await response.json();

                    if (data.success !== true) {
                        // TODO error indication
                        this.hasError = 'update';
                        Bugsnag.notify(data);
                    } else {
                        const contract = data.data;

                        this.state = contract.state;
                        this.assignee = contract.assignee;
                        this.logs = contract.logs;
                        this.form.comment = '';
                    }
                } else {
                    this.hasError = 'update';
                    Bugsnag.notify(await response.json());
                }
            },

            editComment(event, logId) {
                const comment = event.target.closest('tr').querySelector('[data-comment]');

                this.origComment = comment.textContent;
                this.editingComment = logId;

                this.$nextTick(() => {
                    const selection = window.getSelection(),
                        range = document.createRange();

                    range.setStart(comment, 0);
                    range.setEnd(comment, 1);
                    selection.removeAllRanges();
                    selection.addRange(range);
                });
            },

            async updateComment(event, logId) {
                const comment = event.target.closest('tr').querySelector('[data-comment]');

                window.getSelection().removeAllRanges();
                this.editingComment = null;

                this.commentUpdateLoading = logId;
                this.hasCommentUpdateError = false;

                let response = await fetch(this.commentEndpoint.replace('%s', logId), {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        comment: comment.textContent,
                    }),
                });

                if (response.ok) {
                    const data = await response.json();

                    if (data.success !== true) {
                        // TODO error indication
                        this.hasCommentUpdateError = logId;

                        this.editComment(event, logId);
                    } else {
                        this.logs.filter((log) => log.id === logId).comment = comment.textContent;
                    }
                } else {
                    this.hasCommentUpdateError = logId;

                    this.editComment(event, logId);
                }

                this.commentUpdateLoading = false;
            },

            cancelEditComment(event) {
                const comment = event.target.closest('tr').querySelector('[data-comment]');

                window.getSelection().removeAllRanges();
                this.editingComment = null;
                comment.textContent = this.origComment;
            },

            isEditCommentButtonVisible(log) {
                return (
                    log.updated_by === this.userId &&
                    this.editingComment === null &&
                    this.commentUpdateLoading === false
                );
            },
            isUpdateCommentButtonVisible(log) {
                return (
                    log.updated_by === this.userId &&
                    (this.editingComment === log.id || this.commentUpdateLoading === log.id)
                );
            },
            isCancelEditCommentButtonVisible(log) {
                return (
                    log.updated_by === this.userId &&
                    this.editingComment === log.id &&
                    this.commentUpdateLoading === false
                );
            },
            async deleteContract() {
                if (window.confirm('Sind Sie sicher, dass Sie diesen Vertrag löschen wollen?')) {
                    this.hasError = false;
                    this.loading = 'delete';

                    const response = await fetch(this.endpoint, {
                        method: 'DELETE',
                    });

                    if (response.ok) {
                        const data = await response.json();

                        if (data.success !== true) {
                            this.hasError = 'delete';
                            this.loading = false;
                            Bugsnag.notify(data);
                        } else {
                            location.href = this.dashboardURL;
                        }
                    } else {
                        this.hasError = 'delete';
                        this.loading = false;
                        Bugsnag.notify(await response.json());
                    }
                }
            },
            formatDateTime(date) {
                const contractDate = new Date(date);

                return `${contractDate.toLocaleDateString('de-AT', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                })}, ${contractDate.toLocaleTimeString('de-AT')} `;
            },
        },
    });
}
