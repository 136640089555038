import EOTState from './state';
import Vue from 'vue';

export function initDashboard(dashboardContainer) {
    const userRole = dashboardContainer.dataset.userRole || 'sales_person',
        userDCode = dashboardContainer.dataset.userDCode,
        contractLink = new URL(dashboardContainer.dataset.contractLinkBase),
        downloadEndpointBase = dashboardContainer.dataset.downloadEndpoint,
        updateEndpointBase = dashboardContainer.dataset.updateEndpoint;

    let allContracts = [];

    try {
        allContracts = JSON.parse(document.getElementById('eot-dashboard-contracts').textContent);
    } catch (e) {
        //
    }

    new Vue({
        el: dashboardContainer,
        name: 'EOTDashboard',
        components: { 'eot-state': EOTState },
        data() {
            return {
                userRole,
                userDCode,
                state: '',
                region: '',
                search: '',
                allContracts,
                contractLink,
                currentPage: 1,
                itemsPerPage: 10,
                downloadEndpointBase,
                contractTypes: 'all',
                contractEndDateSort: '',
                checkedDeleteContracts: [],
                selectAllContractsOnPageCheckbox: false,
            };
        },
        computed: {
            contracts(data) {
                let contracts = [...data.allContracts];

                if (data.contractTypes === 'own') {
                    contracts = contracts.filter(({ d_code: dCode }) => dCode === data.userDCode);
                }

                if (data.state && data.state !== '') {
                    contracts = contracts.filter(({ state }) => state.value === data.state);
                }

                if (data.region && data.region !== '') {
                    contracts = contracts.filter(({ region }) => region.value === data.region);
                }

                if (data.contractEndDateSort === 'asc') {
                    contracts.sort((a, b) => {
                        return new Date(b.contract_end) - new Date(a.contract_end);
                    });
                } else if (data.contractEndDateSort === 'desc') {
                    contracts.sort((a, b) => {
                        return new Date(a.contract_end) - new Date(b.contract_end);
                    });
                }

                if (data.search) {
                    const searchTerm = data.search.toLowerCase();

                    contracts = contracts.filter((contract) => {
                        let searchableProperties = ['contract_id', 'customer_name'];

                        if (this.userRole === 'sales_admin' || this.userRole === 'sales_manager') {
                            searchableProperties = [
                                'contract_end',
                                'merchant_name',
                                'merchant_code',
                                'customer_name',
                                'vk_name',
                                'contract_id',
                            ];
                        } else if (this.userRole === 'eot_agent') {
                            searchableProperties = [
                                'contract_end',
                                'customer_name',
                                'car_model',
                                'd_code',
                                'vk_name',
                                'contract_id',
                            ];
                        } else if (this.userRole === 'sales_person') {
                            searchableProperties = ['contract_end', 'customer_name', 'car_model', 'contract_id'];
                        }

                        return searchableProperties.some(
                            (prop) => String(contract[prop]).toLowerCase().indexOf(searchTerm) !== -1,
                        );
                    });
                }

                return contracts;
            },
            paginatedContracts(data) {
                const startIndex = this.itemsPerPage * (data.currentPage - 1);

                return data.contracts.slice(startIndex, startIndex + this.itemsPerPage);
            },
            firstPage() {
                return 1;
            },
            lastPage(data) {
                return Math.ceil(data.contracts.length / data.itemsPerPage);
            },
            downloadEndpoint(data) {
                const endpoint = new URL(data.downloadEndpointBase);

                endpoint.searchParams.set('state', data.state);
                endpoint.searchParams.set('region', data.region);

                return endpoint.toString();
            },
            contractIds() {
                return window.btoa(this.contracts.map(({ id }) => id).join('%'));
            },
            downloadURLParams(data) {
                return new URLSearchParams({
                    state: data.state,
                    region: data.region,
                    ids: window.btoa(JSON.stringify(data.contracts.map(({ id }) => id))),
                });
            },
        },
        watch: {
            // prevent having empty dashboard when filtered content doesn't include as many items as without filter
            search() {
                this.currentPage = this.firstPage;
                this.resetSelectedContracts();
            },
            state() {
                this.currentPage = this.firstPage;
                this.resetSelectedContracts();
            },
            region() {
                this.currentPage = this.firstPage;
                this.resetSelectedContracts();
            },
            contractTypes() {
                this.currentPage = this.firstPage;
                this.resetSelectedContracts();
            },
            currentPage() {
                this.resetSelectedContracts();
            },
        },
        methods: {
            preventFormSubmitWithNoContracts(event) {
                if (this.contracts.length === 0) {
                    // prevent displaying json response when there are no contracts
                    event.preventDefault();
                }
            },
            resetSelectedContracts() {
                this.checkedDeleteContracts = [];
                this.selectAllContractsOnPageCheckbox = false;
            },
            getContractLink(id) {
                this.contractLink.searchParams.set('id', id);
                return this.contractLink.toString();
            },
            showFirstPage() {
                this.currentPage = this.firstPage;
            },
            showPreviousPage() {
                this.currentPage = Math.max(this.firstPage, this.currentPage - 1);
            },
            showNextPage() {
                this.currentPage = Math.min(this.lastPage, this.currentPage + 1);
            },
            showLastPage() {
                this.currentPage = this.lastPage;
            },
            showPage(page) {
                this.currentPage = Math.max(Math.min(this.lastPage, page), this.firstPage);
            },
            sortContractByEndDate() {
                if (this.contractEndDateSort === '') {
                    this.contractEndDateSort = 'asc';
                } else if (this.contractEndDateSort === 'asc') {
                    this.contractEndDateSort = 'desc';
                } else if (this.contractEndDateSort === 'desc') {
                    this.contractEndDateSort = 'asc';
                }
            },
            selectAllContractsOnPage() {
                let contracts = document.querySelectorAll('[data-delete-contract-checkbox]'),
                    selectAllContractsCheckbox = document.querySelector('[data-select-all-contracts-on-page-checkbox]');

                if (selectAllContractsCheckbox.checked === true) {
                    contracts.forEach((el) => {
                        // select contracts
                        this.checkedDeleteContracts.push(el.dataset.contractId);
                    });
                } else {
                    contracts.forEach((el) => {
                        // de-select contracts
                        this.checkedDeleteContracts = this.checkedDeleteContracts.filter(
                            (contractId) => contractId !== el.dataset.contractId,
                        );
                    });
                }
            },
            async deleteSelectedContracts() {
                let endpoint = '',
                    endpoints = [],
                    deletedContracts = [];

                this.checkedDeleteContracts.forEach((contractId) => {
                    endpoints.push(contractId);
                });

                endpoint = new URL(updateEndpointBase.replace('%s', endpoints));

                if (endpoints.length > 0) {
                    if (window.confirm('Sind Sie sicher, dass Sie diese Verträge löschen wollen?')) {
                        const response = await fetch(endpoint.href, {
                            method: 'DELETE',
                        });

                        if (response.ok) {
                            const data = await response.json();

                            if (data.success === true) {
                                this.checkedDeleteContracts.forEach((contractId) => {
                                    deletedContracts.push(contractId);
                                });

                                // remove deleted contracts from dashboard
                                this.allContracts = this.allContracts.filter(
                                    (contract) => deletedContracts.includes(contract.contract_id) === false,
                                );

                                // remove contracts from checkedDeleteContracts
                                this.checkedDeleteContracts = this.checkedDeleteContracts.filter(
                                    (id) => deletedContracts.includes(id) === false,
                                );

                                this.resetSelectedContracts();

                                // go to last page if all contracts of last page got deleted
                                if (this.currentPage > this.lastPage) {
                                    this.currentPage = this.lastPage;
                                }
                            }
                        }
                    }
                }
            },
        },
    });
}
