export async function eotDashboard() {
    const dashboardContainer = document.getElementById('eot-dashboard');

    if (dashboardContainer) {
        const { initDashboard } = await import(/* webpackMode: "eager" */ './dashboard');

        initDashboard(dashboardContainer);
    } else {
        const contractContainer = document.getElementById('eot-contract');

        if (contractContainer) {
            const { initContract } = await import(/* webpackMode: "eager" */ './contract');

            initContract(contractContainer);
        }
    }
}
