// eslint: default export is required for vue components
// eslint-disable-next-line import/no-default-export
export default {
    name: 'eot-state',
    template: `<span class="eot-state v-cloak" :title="showLabel ? false : state.label">
        <i class="eot-state__indicator icon icon--dot" :class="stateIndicatorClass" aria-hidden="true"></i>
        <span class="eot-state__label" v-if="showLabel" v-text="state.label"></span>
    </span>`,
    props: {
        state: {
            type: Object,
            required: true,
        },
        showLabel: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        stateIndicatorClass({ state }) {
            const classBase = 'eot-state__indicator--';

            if (state.value.startsWith('act')) {
                return classBase + 'active';
            }

            if (state.value.startsWith('don')) {
                return classBase + 'success';
            }

            if (state.value.startsWith('dec')) {
                return classBase + 'declined';
            }

            return classBase + 'inactive';
        },
    },
};
