import $ from 'jquery';

/* eslint-disable lines-around-comment */
import { Foundation } from 'foundation-sites/js/foundation.core';
Foundation.addToJquery($);

import { rtl, GetYoDigits, transitionend } from 'foundation-sites/js/foundation.core.utils';
Foundation.rtl = rtl;
Foundation.GetYoDigits = GetYoDigits;
Foundation.transitionend = transitionend;

import { Box } from 'foundation-sites/js/foundation.util.box';
// import { onImagesLoaded } from 'foundation-sites/js/foundation.util.imageLoader';
import { Keyboard } from 'foundation-sites/js/foundation.util.keyboard';
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';
import { Motion, Move } from 'foundation-sites/js/foundation.util.motion';
import { Nest } from 'foundation-sites/js/foundation.util.nest';
import { Timer } from 'foundation-sites/js/foundation.util.timer';

Foundation.Box = Box;
// Foundation.onImagesLoaded = onImagesLoaded;
Foundation.Keyboard = Keyboard;
Foundation.MediaQuery = MediaQuery;
Foundation.Motion = Motion;
Foundation.Move = Move;
Foundation.Nest = Nest;
Foundation.Timer = Timer;

import { Touch } from 'foundation-sites/js/foundation.util.touch';
Touch.init($);

import { Triggers } from 'foundation-sites/js/foundation.util.triggers';
Triggers.init($, Foundation);

// import { Abide } from 'foundation-sites/js/foundation.abide';
// Foundation.plugin(Abide, 'Abide');

import { Accordion } from 'foundation-sites/js/foundation.accordion';
Foundation.plugin(Accordion, 'Accordion');

import { AccordionMenu } from 'foundation-sites/js/foundation.accordionMenu';
Foundation.plugin(AccordionMenu, 'AccordionMenu');

// import { Drilldown } from 'foundation-sites/js/foundation.drilldown';
// Foundation.plugin(Drilldown, 'Drilldown');

import { Dropdown } from 'foundation-sites/js/foundation.dropdown';
Foundation.plugin(Dropdown, 'Dropdown');

import { DropdownMenu } from 'foundation-sites/js/foundation.dropdownMenu';
Foundation.plugin(DropdownMenu, 'DropdownMenu');

// import { Equalizer } from 'foundation-sites/js/foundation.equalizer';
// Foundation.plugin(Equalizer, 'Equalizer');

// import { Interchange } from 'foundation-sites/js/foundation.interchange';
// Foundation.plugin(Interchange, 'Interchange');

// import { Magellan } from 'foundation-sites/js/foundation.magellan';
// Foundation.plugin(Magellan, 'Magellan');

import { OffCanvas } from 'foundation-sites/js/foundation.offcanvas';
Foundation.plugin(OffCanvas, 'OffCanvas');

// import { Orbit } from 'foundation-sites/js/foundation.orbit';
// Foundation.plugin(Orbit, 'Orbit');

import { ResponsiveMenu } from 'foundation-sites/js/foundation.responsiveMenu';
Foundation.plugin(ResponsiveMenu, 'ResponsiveMenu');

// import { ResponsiveToggle } from 'foundation-sites/js/foundation.responsiveToggle';
// Foundation.plugin(ResponsiveToggle, 'ResponsiveToggle');

import { Reveal } from 'foundation-sites/js/foundation.reveal';
Foundation.plugin(Reveal, 'Reveal');

// import { Slider } from 'foundation-sites/js/foundation.slider';
// Foundation.plugin(Slider, 'Slider');

import { SmoothScroll } from 'foundation-sites/js/foundation.smoothScroll';
Foundation.plugin(SmoothScroll, 'SmoothScroll');

// import { Sticky } from 'foundation-sites/js/foundation.sticky';
// Foundation.plugin(Sticky, 'Sticky');

// import { Tabs } from 'foundation-sites/js/foundation.tabs';
// Foundation.plugin(Tabs, 'Tabs');

import { Toggler } from 'foundation-sites/js/foundation.toggler';
Foundation.plugin(Toggler, 'Toggler');

// import { Tooltip } from 'foundation-sites/js/foundation.tooltip';
// Foundation.plugin(Tooltip, 'Tooltip');

// import { ResponsiveAccordionTabs } from 'foundation-sites/js/foundation.responsiveAccordionTabs';
// Foundation.plugin(ResponsiveAccordionTabs, 'ResponsiveAccordionTabs');
/* eslint-enable lines-around-comment */

import { toElements } from './utils/to-elements';

export async function init() {
    const body = document.body,
        menuButtons = document.querySelectorAll('.menu-toggle');

    let offCanvasActive = 0,
        inCanvasOffCanvas;

    $(document)
        .foundation()
        .on('opened.zf.offCanvas', (event) => {
            let target = event.target;

            offCanvasActive++;

            body.classList.add('is-off-canvas-open');
            body.classList.add(`is-off-canvas-${target.dataset.transition}-${target.dataset.position}`);

            // only for our main menu off-canvas
            if (event.target.id === 'off-canvas') {
                menuButtons.forEach((menuButton) => {
                    menuButton.classList.add('is-active');
                });
            } else {
                menuButtons.forEach((menuButton) => {
                    menuButton.classList.add('is-disabled');
                });
            }
        })
        .on('close.zf.offCanvas', (event) => {
            let target = event.target;

            offCanvasActive--;

            if (offCanvasActive === 0) {
                body.classList.remove('is-off-canvas-open');
            }

            body.classList.remove(`is-off-canvas-${target.dataset.transition}-${target.dataset.position}`);

            // only for our main menu off-canvas
            if (event.target.id === 'off-canvas') {
                menuButtons.forEach((menuButton) => {
                    menuButton.classList.remove('is-active');
                });
            } else {
                menuButtons.forEach((menuButton) => {
                    menuButton.classList.remove('is-disabled');
                });
            }
        });

    // Foundation hides everything inside the off-canvas component from screen readers, this needs to be fixed:
    inCanvasOffCanvas = toElements('.off-canvas').map((offCanvas) => {
        if (/(^|\s)in-canvas-for-(.*?)(\s|$)/i.test(offCanvas.classList.toString())) {
            let breakpoint = RegExp.$2;

            return {
                offCanvas,
                breakpoint,
            };
        }

        return false;
    });

    if (inCanvasOffCanvas.length) {
        let { eventListenerOptions } = await import(/* webpackMode: "eager" */ '@spinnwerk/polyfills'),
            onResize = () => {
                inCanvasOffCanvas.forEach((item) => {
                    if (Foundation.MediaQuery.atLeast(item.breakpoint)) {
                        item.offCanvas.setAttribute('aria-hidden', false);
                    } else {
                        item.offCanvas.setAttribute(
                            'aria-hidden',
                            item.offCanvas.classList.contains('is-open') !== true,
                        );
                    }
                });
            };

        window.addEventListener('resize', onResize, eventListenerOptions({ passive: true }));
        onResize();
    }
}
