/** @module application */

import { eventListenerOptions } from '@spinnwerk/polyfills';

/**
 * Manages clickability of card components.
 * Clickable card components are identified by class `.card` and attribute `data-clickable`.
 * @see https://inclusive-components.design/cards/
 * @returns {Promise<void>}
 */
export async function cards() {
    const cards = new Set();

    document.querySelectorAll('.card[data-clickable]').forEach((card) => {
        let swiper = card.closest('.cards--swiper');

        if (swiper) {
            cards.add(swiper);
        } else {
            cards.add(card);
        }
    });

    cards.forEach((card) => {
        let listenerOptions = eventListenerOptions({ passive: true }),
            down,
            downTarget,
            downCoords,
            up,
            upTarget,
            upCoords,
            moveThreshold = 10;

        // selecting text should not trigger a click, so the time between mouse down and mouse up is reduced to
        // trigger clicks less often.
        card.addEventListener(
            'mousedown',
            (event) => {
                down = Date.now();
                downTarget = event.target;
                downCoords = {
                    x: event.clientX,
                    y: event.clientY,
                };
            },
            listenerOptions,
        );

        card.addEventListener(
            'mouseup',
            (event) => {
                up = Date.now();
                upTarget = event.target;
                upCoords = {
                    x: event.clientX,
                    y: event.clientY,
                };

                const clickableCard = upTarget.closest('.card[data-clickable]'),
                    link = clickableCard && clickableCard.querySelector('.js-card-link');

                if (
                    up - down < 200 &&
                    downTarget === upTarget &&
                    Boolean(downTarget.closest('.js-card-link')) === false &&
                    downTarget.matches('a') === false &&
                    Boolean(downTarget.matches('.js-card-link')) === false &&
                    Math.abs(downCoords.x - upCoords.x) <= moveThreshold &&
                    Math.abs(downCoords.y - upCoords.y) <= moveThreshold &&
                    Boolean(clickableCard) &&
                    Boolean(link) &&
                    upTarget !== link
                ) {
                    if (event.button === 0) {
                        link.click();
                    } else if (event.button === 1) {
                        // emulate middle mouse button opening a new tab/window
                        let target = link.target,
                            hasNoReferrer = link.relList.contains('noreferrer');

                        link.target = '_blank';

                        if (!hasNoReferrer) {
                            link.relList.add('noreferrer');
                        }

                        link.click();

                        if (target) {
                            link.target = target;
                        } else {
                            link.removeAttribute('target');
                        }

                        if (!hasNoReferrer) {
                            link.relList.remove('noreferrer');
                        }
                    }
                }
            },
            listenerOptions,
        );
    });
}
