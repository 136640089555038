/**
 * Note: this file only serves as entry point and imports all required functionality from modules.
 * These modules are responsible to check whether they are applicable on the current site and only then load their business logic.
 * This way, we can have the best of both worlds, tree-shaking and dynamic imports.
 *
 * When writing modules, make sure to 'register' them in the corresponding index.js and reference them only over this file.
 *
 * If a module is completely not used on a project, comment the corresponding function calls here.
 */

// breakpoint on which headroom should be active
// NOTE: has to match breakpoint in resources/components/molecules/headroom/headroom.scss
const breakpoint = 'desktop';

import * as application from './application';

// ////////////////////////////////////////////////////////////
// run bugsnag as early as possible
application.bugsnag();

// ////////////////////////////////////////////////////////////
// check if cookies are enabled
// - add the .no-cookies class to <html /> if not
let cookieEnabled = Boolean(navigator.cookieEnabled);

if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
    document.cookie = 'testcookie';
    cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
}

if (!cookieEnabled) {
    document.documentElement.classList.add('no-cookies');
}

// ////////////////////////////////////////////////////////////
// include critical polyfills very early
import * as polyfills from '@spinnwerk/polyfills';
polyfills.focusOptions();

// IE11 polyfills
polyfills.dom();
polyfills.fetch();
polyfills.objectFit();
polyfills.picturefill();
polyfills.intersectionObserver();

// ////////////////////////////////////////////////////////////
// image lazy loading
import(/* webpackMode: "lazy" */ 'lazysizes');

// enable support for native lazy loading
/* note: native lazy loading has two major downsides:
 * it loads images much earlier than lazysizes when scrolling down and has no possibility to adjust this value
 * and it loads *all* images above or near the 'fold' regardless of their current visibility state (e.g. in sliders/accordions etc).
 * See https://web.dev/native-lazy-loading#how-does-the-loading-attribute-work-with-images-that-are-in-the-viewport-but-not-immediately-visible-(for-example-behind-a-carousel)
 * If your project is fine with this, feel free to enable the following code and remove the above import:
// import lazySizes from 'lazysizes';
// import 'lazysizes/plugins/native-loading/ls.native-loading';
// lazySizes.cfg.nativeLoading = {
//     setLoadingAttribute: true,
//     disableListeners: true, // remove all event listeners added by lazysizes in browsers that support native lazy loading
// };
 */

// ////////////////////////////////////////////////////////////
// Framework initialization
import * as foundation from './_foundation';
foundation.init();

// ////////////////////////////////////////////////////////////
// Hack vor having full 100vh support on mobile devices
import { fullHeightOnMobile } from './utils/full-height-on-mobile';
fullHeightOnMobile();

// ////////////////////////////////////////////////////////////
// Security
import { initSecuringExternalAnchors } from './security/external-anchors';
initSecuringExternalAnchors();

// ////////////////////////////////////////////////////////////
// Application setup

// register service worker in production mode
application.serviceWorker({ unregister: true });

// optimized font loading
// fonts are defined in app/etc/.fontsrc.json and automatically added to CONFIG
import { loadFonts } from '../../components/base/fonts/fonts';
loadFonts(CONFIG.fonts);
delete CONFIG.fonts; // release memory, fonts are not needed anymore

// ux / accessibility features
application.showFocusOnTabKey();

// init all scrolling belonging
application.autoScrolling({
    fromHashChanges: CONFIG.scrollFromHashChanges,
    offset: {
        small: 0,
        [breakpoint]: {
            up: '.header',
            down: 0,
        },
    },
    getTargetId(hash) {
        return hash.replace(/#\/?/, '');
    },
    beforeScroll(target, $target) {
        /* eslint-disable jquery/no-is, jquery/no-closest */
        // jQuery is required to open targeted Accordion-items anyways, so it is fine to use it */
        if ($target.is('[data-tab-content]')) {
            $target.closest('[data-accordion]').foundation('down', $target);
        }
        /* eslint-enable */
    },
});

// ////////////////////////////////////////////////////////////
// Navigation

// ////////////////////////////////////////////////////////////
// form helpers
import * as form from '../../components/patterns/molecules/form';

function initFormHelpers() {
    // init floating-label-inputs
    form.floatingLabel();

    // enable validation
    form.validation().then(() => {
        form.steps(); // validation needs to be in place before multi-step forms can be initialized

        // automatically attach a loading indicator to submit buttons on form submit
        form.loadingIndicator({
            exclude: '.filter-search .button',
        });
    });

    // enable automatic summaries
    form.summaries();

    // enable conditional inputs
    form.conditionals();

    // enable (de-)selecting all checkboxes at once
    form.selectAll();

    // style advanced file inputs
    form.fileInput({
        multipleFilesLabel: ':anz Dateien ausgewählt',
    });

    // enable multi file uploads
    form.multiUpload();

    // manage hierarchical checkboxes
    form.hierarchicalCheckboxes();
}

initFormHelpers();

// WooCommerce replaces DOM completely, so all functionality must be applied again (-> missing event handlers)
document.body.addEventListener(
    'updated_wc_div',
    () => initFormHelpers(),
    polyfills.eventListenerOptions({ passive: true }),
);

// ////////////////////////////////////////////////////////////
// End of term-Dashboard
import { eotDashboard } from '../../components/eot/js';
eotDashboard();

// ////////////////////////////////////////////////////////////
// atoms
import { animateSVG } from '../../components/patterns/atoms/animate';
animateSVG();

// ////////////////////////////////////////////////////////////
// molecules
import { initHeadroom } from '../../components/patterns/molecules/headroom';
application.globalState.set(
    'headroom',
    initHeadroom({
        // add the spacer only if there is no hero element
        prependSpacerTo: document.querySelector('.wrapper .teaser--hero') ? false : '.off-canvas-content',
        breakpoint,
    }),
);

import { fontSizeSwitcher } from '../../components/patterns/molecules/font-size-switcher';
fontSizeSwitcher();

import { cards } from '../../components/patterns/molecules/card/cards';
cards();

import { counter } from '../../components/patterns/molecules/counter';
counter();

import { filters } from '../../components/patterns/molecules/filter';
filters();

import { lightbox } from '../../components/patterns/molecules/lightbox';
lightbox();

// ////////////////////////////////////////////////////////////
// layouts
import { masonry } from '../../components/layout/masonry';
masonry();

import { swiper } from '../../components/layout/swiper';
swiper();

// import { maps } from '../../components/layout/map';
// maps();

// ////////////////////////////////////////////////////////////
// Chart
import { chart } from '../../components/patterns/organisms/chart';
chart();

// ////////////////////////////////////////////////////////////
// browser alerts
import { oldBrowserAlert } from '../../components/patterns/molecules/browser-alerts';
oldBrowserAlert();
